import React from 'react';
import styled from 'styled-components';


const LogoWrapper = styled.svg`
  width: 100%;
  height: auto;
  position: relative;
  /* top: 0.3em; */
  margin-right: 0em;
`;

export default function TuLogo(props) {
  const color = props.color || 'white';
  return (
    <LogoWrapper viewBox='0 0 100 100'>
      <g id="TU" stroke="none" strokeWidth={1} fill="none" fillRule="evenodd">
        <g transform="translate(4, 4)" fill={color} fillRule="nonzero" id="g3329" stroke="white" strokeWidth={3}>
          <g transform="translate(46.3327, 45.9656) scale(-1, 1) rotate(-180) translate(-46.3327, -45.9656)translate(-0, 0)">
            <path d="M69.1794336,41.5961009 C62.5638944,39.517224 55.733299,41.1813496 55.7486601,50.039618 C55.7589009,63.5369565 87.4592129,74.8530104 91.8525044,86.6452602 C92.9431467,89.584362 93.0097118,92.0063048 91.6016055,91.929499 C90.5928894,91.8731747 91.3814289,90.4087442 89.1643016,88.1813761 C76.0305105,75.0475851 54.0333307,75.0680666 37.1514168,67.505256 C26.0811415,62.5333608 -6.36162662,47.269489 1.10389672,13.2137992 C1.46232376,11.6008775 2.4249564,6.17838834 3.41831136,6.17838834 C4.56527791,6.17838834 4.55503713,9.39911137 4.52431482,13.3110866 C4.27853627,33.5878166 28.288028,39.2407232 36.1017376,52.4871627 C37.0336479,54.0744825 38.65169,56.2967302 39.035719,55.2624121 C39.2200529,54.7657347 39.102284,54.028399 38.7848201,52.6202928 C36.3372754,41.7343513 24.9546565,34.7450239 28.1804999,26.967157 C32.3587352,16.8646347 44.4326062,24.3967231 48.1346456,30.889373 C49.1280005,32.6763878 49.6861226,33.818234 50.3517729,33.5878166 C50.8484504,33.4137235 50.8330892,31.3604485 50.5207456,29.5171094 C48.5391561,17.8016654 45.8458329,11.2219689 37.2691857,4.55522586 C34.529779,2.42002475 30.2132933,1.97455113 30.7816561,0.336027491 C30.925027,-0.088964578 32.7837272,-0.0428811006 34.2635189,0.105610104 C57.0953217,1.56492022 76.1943629,28.4879118 81.2533046,46.0713186 C81.7807044,47.3104521 81.9445568,48.4727798 81.3813143,48.9080126 C80.6951825,49.4405328 79.6915868,48.2167605 78.6726299,47.3155725 C76.0612329,45.0216394 72.4820828,42.6355393 69.1794336,41.5961009" id="path3331" />
          </g>
        </g>
      </g>

    </LogoWrapper>

  );
}
