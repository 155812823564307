import React, { Component, Fragment } from 'react';
import { connect } from 'react-redux';
import styled, { ThemeConsumer } from 'styled-components';
import AhLogo from './AhLogo';
import { findTimeZone, getZonedTime, format } from 'timezone-support';
import { toDate, format as formatTz, utcToZonedTime } from 'date-fns-tz';
import { nl as localeNL } from 'date-fns/locale';
import nl_flag from './flags/nl.svg';
import background from './Themes/Efteling/KlaasVaak/back.png';

const colors = {
  primary: '#F6F6F6',
  secundary: '#848484'
};

const FlexOuterWrapper = styled.div`
  font-family: 'Inter', sans-serif;
  font-feature-settings: 'dlig' on;
  display: flex;
  flex-direction: column;
  align-items: center;
  color: ${colors.primary};
  justify-content: space-between;
  height: 100vh;
  background: ${props => (props.hoursLeft > 4) ? `url(${background})` : 'none'};
  background-size: cover;
`;

const AlternateA = styled.span`
  font-variation-settings: 'ss01' on;
`;

const TopText = styled.div`
  font-size: 6vw;
  font-weight: 600;
  color: ${colors.secundary};
  margin-top: 5vw;
  text-shadow: 3px 3px 0 black, -3px -3px 0 black, 3px -3px 0 black, -3px 3px 0 black;
`;

const StartTime = styled.span`
  color: ${colors.primary};
`;

const CountdownWrapper = styled.div`
  text-shadow: 5px 5px 0 black, -5px -5px 0 black, 5px -5px 0 black, -5px 5px 0 black;
`;

const CountdownValue = styled.span`
    font-size: 30vw;
  font-weight: 600;
`;
const CountdownUnit = styled.span`
    font-size: 16vw;
  font-weight: 600;
  margin-left: 1.2vw;
  /* text-transform: uppercase; */
`;

const CountdownVertrek = styled.span`
  text-transform: uppercase;
  font-size: 16vw;
  font-weight: 600;
`;

const BottomClock = styled.div`
  font-size: 8vw;
  font-weight: 600;
  margin-bottom: 5vw;
  text-shadow: 3px 3px 0 black, -3px -3px 0 black, 3px -3px 0 black, -3px 3px 0 black;
  transform: ${props => props.timeToLeave ? 'scale(1.4)' : 'scale(1)'};
  transform-origin: center bottom;
  transition: transform 3s ease-in-out;
`;

const SecundaryColor = styled.span`
  color: ${colors.secundary};
`;

const Flag = styled.img`
  border-radius: 0.9vw;
  height: 5.7vw;
  margin-right: 4rem;
  margin-top: 0.25vw;
  box-shadow: 3px 3px 0 black, -3px -3px 0 black, 3px -3px 0 black, -3px 3px 0 black;
`;

const Countdown = props => {
  const { hoursLeft, minutesLeft } = props;
  // const hoursLeft = 0;
  // const minutesLeft = 34;

  if (hoursLeft < 0 || minutesLeft < 0) {
    return <CountdownWrapper><CountdownVertrek>Vertrek</CountdownVertrek></CountdownWrapper>;
  }

  if (hoursLeft == 0) {
    return (<CountdownWrapper>
      <CountdownValue>{minutesLeft}</CountdownValue>
      <CountdownUnit>min</CountdownUnit>
    </CountdownWrapper>);
  }

  if (minutesLeft == 0) {
    return (<CountdownWrapper>
      <CountdownValue>{hoursLeft}</CountdownValue>
      <CountdownUnit>uur</CountdownUnit>
    </CountdownWrapper>);
  }

  return (<CountdownWrapper>
    <CountdownValue>{hoursLeft}</CountdownValue>
    <CountdownUnit>u </CountdownUnit>
    <CountdownValue>{minutesLeft.toString().padStart(2, '0')}</CountdownValue>
    <CountdownUnit>m</CountdownUnit>
  </CountdownWrapper>);
};

class WorkShift extends Component {
  timeLeft = () => {
    // return 'Over X min';
    // Check if start_time is null or undefined
    if (this.props.ahWorkShift.start_time == null) {
      return "Niet beschikbaar";
    }

    const amsterdamTimeZone = 'Europe/Amsterdam';
    const currentTime = new Date();
    const targetTime = toDate(this.props.ahWorkShift.start_time); // Use toDate from 'date-fns-tz'

    const timeDifference = targetTime - currentTime - 30 * 60 * 1000 + 60000;

    const hoursLeft = Math.floor(timeDifference / (1000 * 60 * 60));
    const minutesLeft = Math.floor((timeDifference % (1000 * 60 * 60)) / (1000 * 60));

    return { hoursLeft, minutesLeft };
  };

  toLocalTime = isoDateTimeString => {
    if (this.props.ahWorkShift.start_time == null) {
      return "?";
    }
    const amsterdamTimeZone = 'Europe/Amsterdam';
    const zonedTime = utcToZonedTime(isoDateTimeString, amsterdamTimeZone);
    const formattedTime = formatTz(zonedTime, 'HH:mm', { timeZone: amsterdamTimeZone, locale: localeNL });
    return formattedTime;
  };

  render() {
    const timeStringNL = this.props.now && this.props.now.toLocaleTimeString('nl-NL', {
      hour12: false,
      hour: 'numeric',
      minute: 'numeric'
    });
    const temperature = (this.props.temperature != null) ? Math.round(this.props.temperature) : null;
    const { hoursLeft, minutesLeft } = this.timeLeft();
    const timeToLeave = hoursLeft < 0 || minutesLeft < 0;

    return (
      <FlexOuterWrapper hoursLeft={hoursLeft}>
        <TopText><AhLogo color='#3A4195' /> shift v<AlternateA>a</AlternateA>n <StartTime>{this.toLocalTime(this.props.ahWorkShift.start_time)}</StartTime> tot {this.toLocalTime(this.props.ahWorkShift.end_time)}</TopText>
        <Countdown hoursLeft={hoursLeft} minutesLeft={minutesLeft} />
        <BottomClock timeToLeave={timeToLeave}>
          <Flag src={nl_flag} />
          {timeStringNL}
          {!timeToLeave && temperature !== null && (
            <SecundaryColor> | {temperature}°</SecundaryColor>
          )}
        </BottomClock>
      </FlexOuterWrapper>
    );
  }
}

const mapStateToProps = state => ({
  now: state.time.now,
  temperature: state.weather.temperature,
  feelsLike: state.weather.feelsLike,
  emoji: state.weather.emoji,
  ahWorkShift: state.ahwork
});

export default connect(mapStateToProps)(WorkShift);
